import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./sliderStyles.scss"; // Import global slider styles
import styles from "./TestimonialsSlider.module.scss"; // Scoped styles

const TestimonialCard = ({ testimonial, animate }) => {
  return (
    <Box
      className={`${styles.card} ${animate ? styles.animateIn : ""}`}
      sx={{ minHeight: "385px", maxWidth: "950px" }}
    >
      <Typography
        sx={{
          color: "#FFF", // White text color
          fontFamily: "Poppins",
          fontSize: "44px", // Font size of 44px
          fontStyle: "normal", // Normal font style
          fontWeight: 700, // Font weight 700 (bold)
          lineHeight: "31px", // Line height of 31px
          marginBottom: "8px", // Existing margin bottom
          height: "13px",
        }}
      >
        “
      </Typography>

      <Typography variant="h6" className={styles.cardTitle}>
        {testimonial?.title}
      </Typography>
      <Typography variant="body1" className={styles.cardDesc}>
        {testimonial?.desc}
      </Typography>
      <Typography
        sx={{
          color: "#FFF", // White text color
          fontFamily: "Cabin", // Cabin font family
          fontSize: "44px", // Font size of 44px
          fontStyle: "normal", // Normal font style
          fontWeight: 700, // Font weight 700 (bold)
          lineHeight: "31px", // Line height of 31px
          marginBottom: "8px", // Existing margin bottom
          height: "13px",
          alignSelf: "end",
        }}
      >
        ”
      </Typography>

      <Box className={styles.authorSection}>
        <img
          src={testimonial?.authorImage}
          alt={testimonial?.authorName}
          className={styles.authorImage}
        />
        <Box>
          <Typography variant="h6" className={styles.authorName}>
            {testimonial?.authorName}
          </Typography>
          <Typography variant="body2" className={styles.authorTitle}>
            {testimonial?.authorTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const TestimonialsSlider = ({ data }) => {
  const [componentInView, setComponentInView] = useState(false); // Track if the entire component has been in view
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once when the component comes into view
    threshold: 0, // Component comes into view when 50% of it is visible
  });

  // Once the component is in view, set the animation trigger
  if (inView && !componentInView) {
    setComponentInView(true);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // Adjust autoplay speed for smoother transitions
    arrows: false, // Disable arrows
    lazyLoad: "progressive", // Enable lazy loading for images and content
    cssEase: "ease-out", // Smooth easing function for transitions
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      className={styles.container}
      sx={{
        backgroundImage: `url(${data?.backgroundImageUrl || ""})`,
        padding: { lg: "60px 0", xs: "45px 0", sm: "45px 0", md: "60px 0" },
      }}
     ref={ref}
    >
      <Typography
        sx={{
          margin: "0px",
          padding: "0px",
          color: "#fff",
          textAlign: "center",
          fontFamily: "Poppins",
          fontSize: { lg: "24px", xs: "16px", sm: "16px", md: "20px" },
          fontStyle: "normal",
          fontWeight: "600 !important",
          lineHeight: "normal",
          letterSpacing: "0.2px",
        }}
      >
        {data?.title || "Don’t just take our word for it"}
      </Typography>

      <Slider
        {...settings}
        className={styles.slider}
        sx={{
          padding: { lg: "60px", md: "45px", sm: "30px", xs: "30px" },
        }}
      >
        {data?.testimonials?.map((testimonial, index) => (
          <TestimonialCard
            key={`testimonial-${index}`} // Ensure unique key
            testimonial={testimonial}
            animate={componentInView}
          />
        ))}
      </Slider>
    </Box>
  );
};
