import "./OurServicesNew.scss";
import blueArrow from "../../Assets/SVG/blueLeftArrow.svg";
import { useState } from "react";

export const OurServicesNew = ({ ComponentData }) => {
  return (
    <div className="ourservicesnew">
      <div className="heading">{ComponentData?.heading}</div>
      <div className="cards-row">
        {ComponentData?.cards?.map((card, index) => (
          <FlipCard
            key={index}
            frontContent={card?.title}
            backContent={card?.desc}
            imageData={card?.image}
          />
        ))}
      </div>
      <div className="cards-phone">
        {ComponentData?.cards?.map((card, index) => (
          <PhoneCards
            key={index}
            frontContent={card?.title}
            backContent={card?.desc}
            imageData={card?.image}
          />
        ))}
      </div>
    </div>
  );
};

const FlipCard = ({ frontContent, backContent, imageData }) => {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className="card-image">
            <img src={imageData} alt="" style={{color:"#0B52D4"}}/>
          </div>
          <div className="card-content"> {frontContent}</div>
        </div>
        <div className="flip-card-back">{backContent}</div>
      </div>
    </div>
  );
};

const PhoneCards = ({ frontContent, backContent, imageData }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleReadMoreClick = () => {
    setIsFlipped(!isFlipped); // Toggle the flipped state
  };
  return (
    <div className={`each-card ${isFlipped ? "flipped" : ""}`}>
      <div className="front">
        <div className="image">
          <img src={imageData} alt="Front" />
        </div>
        <div className="content">{frontContent}</div>
        <div className="read-more" onClick={handleReadMoreClick}>
          <div>Read More</div>
          <div className="arrow">
            <img src={blueArrow} alt="Read More" />
          </div>
        </div>
      </div>
      <div className="back">
        <div className="content">{backContent}</div>
        <div className="read-less" onClick={handleReadMoreClick}>
          <div className="arrow rotated">
            <img src={blueArrow} alt="Read Less" />
          </div>
          <div>Read Less</div>
        </div>
      </div>
    </div>
  );
};
