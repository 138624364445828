import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, CssBaseline, Box, IconButton, Toolbar, AppBar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import {SideBarClient, sideBarData} from "./SideBarClient.jsx"
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

// Set DrawerHeader height manually, equivalent to Toolbar height
const DrawerHeader = styled('div')(() => ({
  minHeight: '64px', // Set the default height for the toolbar area (standard height is 64px)
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center horizontally
  padding: '0 16px',
}));

export default function SideBar() {
  const navigate=useNavigate()
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [selectedIndex, setSelectedIndex] = useState(0); // Track the selected item

  const handleListItemClick = (item,index) => {
    setSelectedIndex(index); // Set the selected item
    navigate(item?.onCLickRoute)
    
  };

  const drawer = (
    <div style={{width:'100%'}}>
      <DrawerHeader>
      <div >
              <a href="/client/searchtalent">
                {/* <a href=""> */}
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={process.env.PUBLIC_URL + "/images/zeero-logo.svg"}
                 
                />
              </a>{" "}
            </div>
    </DrawerHeader>
      <List>
      {sideBarData.map((item, index) => (
  <ListItem
    key={item.header}
    onClick={() => handleListItemClick(item,index)} // Track which item is clicked
    sx={{
      padding:'22px 0px 22px 22px',
      cursor: 'pointer',
      marginBottom: '2px',
      height: '61px',
      backgroundColor: selectedIndex === index ? 'rgba(11, 82, 212, 0.08)' : 'transparent', // Apply blue color when selected
      '&:hover': {
        backgroundColor: selectedIndex === index ? 'rgba(11, 82, 212, 0.08)' : '#f0f0f0', // Optional hover styling
      },
    }}
  >
<Box 
  display="flex" 
  alignItems="center" 
  justifyContent="space-between" 
  sx={{ gap: '16px', position: 'relative', width: '100%', padding: '0', margin: '0' }}  // Ensure full width and no padding or margin
>
  {/* Icon */}
  <item.icon sx={{ color: selectedIndex === index ? '#0B52D4' : '#B4B4B4' }} />

  {/* Text element */}
  <ListItemText
    primary={item.header}
    sx={{
      color: selectedIndex === index ? '#0B52D4' : '#B4B4B4',
      fontFamily: 'Poppins',  // Font family
      fontSize: '18px',  // Font size
      fontWeight: '700',  // Bold font weight
      lineHeight: '27px',  // Line height
      textAlign: 'left',  // Text alignment
      flexGrow: 1,
    }}
  />

  {/* Conditionally render the third element (blue line) */}
  {selectedIndex === index && (
    <Box
      sx={{
        width: '4px',   // Set width of the line
        height: '100%', // Make it cover the full height of the parent Box
        backgroundColor: 'rgba(6, 43, 110, 1)', // Blue color for the line
        position: 'absolute', // Position the line absolutely
        right: 0, // Align the line to the right side of the container
        top: 0,  // Align it to the top
        bottom: 0,  // Stretch to the bottom (full height)
        borderRadius: '2px', // Optional: rounded edges
      }}
    />
  )}
</Box>







  </ListItem>
))}

      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
    

      {/* Permanent drawer for larger screens */}
      <Drawer
  variant="permanent"
  sx={{
    display: { xs: 'none', sm: 'block' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Add your box shadow here
    },
  }}
  open
>
  {drawer}
</Drawer>


      {/* Temporary drawer for mobile screens */}
    

      <Main sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` } }}>
        <DrawerHeader />
        {/* Main content can be added here */}
      </Main>
    </Box>
  );
}
